<template>
  <div
    class="tabbar_wrap"
    :style="{
      // '--tabbarBG': verConfig.tabbar.background || '#fff',
      // '--pageBG': verConfig.pagesBG || '#f5f5f5',
    }"
  >
    <div class="page_placeholder">
      <router-view />
    </div>
    <keep-alive>
      <!-- :style="{ '--color': verConfig.tabbar.color || '#000' }" -->
      <Tabbar
        v-model="active"
        :active-color="'var(--tarbarActiveColor)' || verConfig.theme"
        inactive-color="var(--tarbarColor)"
        route
        fixed
        placeholder
        safe-area-inset-bottom
      >
        <TabbarItem
          v-for="(item, index) in verConfig.tabbar.pages"
          :key="index"
          :name="index"
          :to="`/tabbar/${item.pagePath}`"
          :class="{ special: item.isSpecial }"
        >
          <span>{{ $t(item.text) }}</span>
          <template #icon="props">
            <img
              v-if="verConfig.tabbar.type === 'image'"
              :src="props.active ? item.selectedIconPath : item.iconPath"
              alt=""
            />
              <!-- :style="
                verConfig.tabbar.iconColor && {
                  '--color': props.active
                    ? item.selectedColor ||
                      verConfig.tabbar.selectedColor ||
                      verConfig.theme
                    : item.iconColor || verConfig.tabbar.iconColor,
                }
              " -->
              <!-- :class="
                item.selectedIconPath
                  ? props.active
                    ? item.selectedIconPath
                    : item.iconPath
                  : item.iconPath
              " -->
            <i
              class="iconfont"
              v-else
              :class="{
                icon_active: props.active,
                [item.selectedIconPath]: props.active && item.selectedIconPath,
                [item.iconPath]: !props.active || !item.selectedIconPath
              }"
            >
            </i>
          </template>
        </TabbarItem>
      </Tabbar>
    </keep-alive>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
export default {
  components: {
    Tabbar,
    TabbarItem,
  },
  data: () => ({
    // active: 0,
  }),
  created() {},
  methods: {
    handleClick() {},
    getIcon(item, active) {
      if (active) {
        console.log(item.selectedIconPath || item.iconPath);
        return item.selectedIconPath || item.iconPath;
      }
      return item.iconPath;
    },
  },
  computed: {
    active: {
      get() {
        return this.$store.state.tabbar.active;
      },
      set(newVal) {
        return newVal;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.tabbar_wrap {
  // background: #f5f5f5;
  // background: var(--pageBG);
  background: var(--bg);
  /deep/ .van-tabbar {
    max-width: 750px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    // filter: drop-shadow(0 0 4px #bbb);
    // background: var(--tabbarBG);
    background: var(--tarbarBg);
    &::after {
      border: 1px solid var(--tarbarBg);
    }
    .van-tabbar-item--active {
      background: var(--tarbarBg);
    }
  }
}
.page_placeholder {
  // padding-bottom: calc(50rem / 16);
}
.iconfont {
  font-size: calc(22rem / 16);
  // color: var(--color);
  color: var(--tarbarColor);
}
.icon_active{
  color: var(--tarbarActiveColor);
}
.special {
  margin-top: calc(-20rem / 16);
  border-radius: 50%;
  background: #fff;
  max-width: fit-content;
  padding: calc(6rem / 16);
  .iconfont {
    font-size: calc(50rem / 16);
  }
}
</style>